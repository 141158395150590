import React, { Component } from 'react';
import { getSQLData } from '../../../../assets/functions/fetch';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';

class TechDashboardv2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,

        };
    
      }
      fetchData = async () => {

        try {
            const fetchAll = await getSQLData("cam/allFollowUps", {})
            const fetch = await getSQLData("cam/followUps", {nRep: this.props.nRep})
            if(this.props.nRep === 45 || this.props.nRep === 5){
                if(fetchAll){
                    let data = fetchAll.data.data[0].recordset    
            
                    this.setState({
                      data: data,
                    }, () => this.processData())
                  }
            } else {
                if(fetch){
                    let data = fetch.data.data[0].recordset    
            
                    this.setState({
                      data: data,
                    }, () => this.processData())
                  }
            }
          
          
        } catch (error) {
          console.error(error);
        }
      }
      componentDidMount(){
        this.fetchData()
      }

      processData(){
        let data = this.state.data
        let completed = 0
        let pastDue = 0
        let dueToday = 0

        for(let i=0; i<data.length; i++){
            if(data[i].Completed){
                completed++
            } else {
                if(moment(data[i].FollowUp).isBefore(moment(),'day')){
                    pastDue++
                } else {
                    dueToday++
                }
            }
        }
        console.log(completed,pastDue,dueToday)
        this.setState({
            completed: completed,
            pastDue: pastDue,
            dueToday: dueToday,
            loaded: true,
        })
      }

    render() {
        return (
            <>
            {this.state.loaded && (
                <>
                <div>Completed Today: {this.state.completed}</div>
                <div>Past Due: {this.state.pastDue}</div>
                <div>Due Today: {this.state.dueToday}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Quote</th>
                            <th>Customer</th>
                            <th>Title</th>
                            <th>Rep</th>
                            <th>Follow Up</th>
                            <th>Completed</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    {this.state.data.map((followup,index)=>(
                        <tr>
                            <td><Link target="_blank" to={`/quote/${followup.Quote}`}>{followup.Quote}</Link></td>
                            <td>{followup.sCompany}</td>
                            <td>{followup.sQuoteTitle}</td>
                            <td>{followup.Rep}</td>
                            <td>{followup.FollowUp}</td>
                            <td>{followup.Completed}</td>
                            <td>{followup.sNote}</td>
                        </tr>
                    ))}
                </table>
                </>
            )}
            </>
        );
    }
}

export default TechDashboardv2;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSQLData } from '../../assets/functions/fetch';
import CallLog from '../tables/marketing-dashboard/CallLog';
import MarketingProfileWizard from '../tables/marketing-dashboard/MarketingProfileWizard';
import EmailCampaignTool from '../tables/marketing-dashboard/EmailCampaignTool';

class MarketingDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewing: "Marketing Profile Wizard",
        }
    }

    fetchData = async () => {

    }

    componentDidMount(){
        
    }

    render() {
        return (
            <div>
                <button onClick={()=>this.setState({viewing: "Marketing Profile Wizard"})}>Marketing Profile Wizard</button>
                <button onClick={()=>this.setState({viewing: "Import Call Log"})}>Import Call Log</button>
                <button onClick={()=>this.setState({viewing: "Email Campaign Tool"})}>Email Campaign Tool</button>

                {this.state.viewing === "Import Call Log" && (
                    <CallLog />
                )}
                {this.state.viewing === "Marketing Profile Wizard" && (
                    <MarketingProfileWizard />
                )}
                {this.state.viewing === "Email Campaign Tool" && (
                    <EmailCampaignTool />
                )}
                
            </div>
        );
    }
}

export default MarketingDashboard;
module.exports = {
    logistics_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getOpenRentals: {
            query: "SELECT rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sQuoteType='Rentacomputer.com' "
                + "AND rcq.dtDeliveryDate < DATEADD(month,1,GETDATE()) "
                + "AND rcq.dtPickupDate > DATEADD(month,-2,GETDATE()) "
                + "AND rcq.sStatus = 'Closed' "
                + "ORDER BY rcq.dtDeliveryDate asc",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Gets all open rentals.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        trackingCompleteStatus: {
            query: "UPDATE PACKAGES SET bCompleted=[trackingStatus] WHERE id=[trackingID]",
            req: ["trackingStatus", "trackingID"],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Updates package completion status",
            tables: ["PACKAGES"],
            type: "UPDATE",
            whereUsed: [],
        },
        lateDeliveries: {
            query: "SELECT rcq.id, rcq.dtDeliveryDate, rcq.sCompany, rcq.sDeliveryTracking, "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND (p.sTrackingNumber='EX' OR p.sTrackingNumber='REP')) AS nExceptions "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.id NOT IN (1795753,1797623,1797387,1798203,1795998,1799047,1797892,1798807) "
                + "AND ((rcq.nDeliveryType=2 AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= '6/25/2019 00:00' AND ((rcq.dtDeliveryDate + '23:59' < GetDate() AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 AND p.sStatusCode <> 'DE') > 0) "
                + "OR (SELECT COUNT(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND p.nType=1 AND p.dtEstimatedDelivery >= rcq.dtDeliveryDate+1 + '04:01' AND p.sStatusCode <> 'DE') > 0) "
                + "AND (SELECT COUNT(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND p.sTrackingNumber='Cancelled')=0 "
                + "AND (SELECT Count(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND (p.sTrackingNumber='rem')) = 0 "
                + "AND (SELECT Count(p.id) FROM PACKAGES p WHERE nQuote=rcq.id AND (p.sTrackingNumber='FREIGHT'))=0)) ORDER BY rcq.dtDeliveryDate ",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "sCompany", label: "Customer Company" },
                { key: "dtDeliveryDate", label: "Quote Delivery Date" },
                { key: "nExceptions", label: "Number of Exceptions" },
                { key: "sDeliveryTracking", label: "Delivery Tracking Number" },
            ],
            desc: "Shows all orders where tracking is not scheduled for delivery in time to make the delivery date on the order. "
                + "For customer-caused delivery exceptions, put 'EX' in tracking to remove red status. "
                + "For non-customer caused delays, red note the rep, and put 'REP' in tracking to remove red status. "
                + "To remove quotes from this list put 'REM' in tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        deliveryTracking: {
            query: "SELECT p.* "
                + "FROM PACKAGES p "
                + "WHERE p.nQuote IN "
                + "(SELECT rcq.id FROM rac_customer_quotes rcq WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL "
                + "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com') ",
            req: [],
            fields: [

            ],
            desc: "Lists all packages from CAM Open Installs",
            tables: ["PACKAGES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
            ],

        },
        returnReminders: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nRemindedOfReturn IS NULL AND rcq.nParentQuote IS NULL AND "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nParentQuote = rcq.id AND rcq2.dtPickupDate=rcq.dtPickupDate "
                + "AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0) = 0 "
                + "AND (SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nParentQuote = rcq.id AND rcq2.dtQuoteDate>=rcq.dtPickupDate "
                + "AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0) = 0 "
                + "AND (SELECT COUNT(p.id) FROM PACKAGES p WHERE p.sStatusCode NOT IN ('IT', 'DE') AND p.nQuote=rcq.id AND p.nType=2) > 0 "
                + "AND rcq.dtPickupDate >= DATEADD(day,1,GETDATE()) AND rcq.dtPickupDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id ",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
            ],
            desc: "List of quotes that are due to be returned tomorrow but the return tracking isn't active yet. "
                + "Remind the customer of their return the day before it is due. "
                + "Don't forget to remind the vendor on vendor deliveries.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        vendorPreDelivery: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed < GETDATE() AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType = 1 AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote = rcq.id AND p.sTrackingNumber='VPD') = 0 "
                + "AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,1,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) AND rcq.nContractType <> 3 ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "List of quotes that are due to be delivered tomorrow but haven't been confirmed with the vendor yet. "
                + "Confirm the details of all vendor delivery orders with the vendor, the day before delivery. "
                + "Put 'VPD' in tracking to remove them from this list.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        customerPreDelivery: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed < GETDATE() AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType = 1 AND "
                + "(SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.sTrackingNumber='CPD') = 0 "
                + "AND rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,1,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,"
                + "CASE WHEN DATENAME(dw,GETDATE())='Friday' THEN 3 WHEN DATENAME(dw,GETDATE())='Saturday' THEN 2 ELSE 1 END, "
                + "GETDATE()) AND rcq.nContractType <> 3 ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "List of quotes that are due to be delivered tomorrow but haven't been confirmed with the customer yet. "
                + "After the vendor confirms the details, remind the customer of their delivery. "
                + "Put 'CPD' in tracking to remove them from this list. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        addLateCallNote: {
            query: "INSERT INTO RAC_QUOTE_NOTES ("
                + "nQuote, sNote, nRep, nType, dtTimeStamp, nCategory"
                + ") VALUES ("
                + "[quoteNum], '[noteText]', [repID], 1, GETDATE(), 11)",
            req: ["quoteNum", "noteText", "repID",],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Adds a note to the system with category Logistics - Late Return Call.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "INSERT",
            whereUsed: [],
        },
        ordersNotYetReturnedv2: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate, rcq.sCompany, "
                + "rcq.sName, rcq.sPhone, rcq.sEmail, rcq.sState, rcq.sDName, rcq.sDPhone, rcq.sDState, "
                + "(SELECT COUNT(rqn.id) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS nLateCalls, "
                + "(SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS dtLastCall, "
                + "(SELECT TOP 1 sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11 ORDER BY rqn.dtTimeStamp desc) AS sLastNote "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.dtPickupDate > DATEADD(month,-1,GETDATE()) AND rcq.nDeliveryType IN (0,2) AND rcq.sStatus='Closed' AND rcq.dtPickupDate <= GETDATE() "
                + "AND ("
                    + "SELECT COUNT(rcq2.id) "
                    + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                    + "WHERE rcq2.nParentQuote=rcq.id AND rcq2.dtPickupDate>=rcq.dtPickupDate AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0"
                + ") = 0 "
                + "AND ("
                    + "SELECT COUNT(p.id) "
                    + "FROM PACKAGES p "
                    + "WHERE p.sStatusCode NOT IN ('IT','DE') AND NOT (p.sStatusCode='AC' AND p.sCarrierCode='FedEx') "
                    + "AND p.nQuote=rcq.id AND p.nType=2 AND (p.bCompleted=0 OR p.bCompleted IS NULL) "
                + ") > 0 "
                + "AND (NOT (SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) < DATEADD(day,-1,GETDATE()) OR (SELECT COUNT(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) = 0) "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "sCompany", label: "Company Name"},
                {key: "nRep", label: "Sales Rep ID"},
                {key: "nDeliveryType", label: "Delivery or Shipping"},
                {key: "dtPickupDate", label: "Quote Pickup Date"},
                {key: "sCompany", label: "Customer Name"},
                {key: "sName", label: "Contact Name"},
                {key: "sPhone", label: "Contact Phone"},
                {key: "sEmail", label: "Contact Email"},
                {key: "sState", label: "Contact State"},
                {key: "sDName", label: "Delivery Contact Name"},
                {key: "sDPhone", label: "Delivery Contact Phone"},
                {key: "sDState", label: "Delivery Contact State"},
                {key: "nLateCalls", label: "Late Call Notes"},
                {key: "dtLastCall", label: "Date of Most Recent Last Call"},
                {key: "sLastNote", label: "Text of Most Recent Last Call Note"},
            ],
            desc: "Orders show up here if tracking hasn't been activated by the return date. "
                + "Send reminders to customers who are due to ship back today, and call them. "
                + "Make a note of type Logistics - Late Return Call. "
                + "Repeat this process the next business day. "
                + "If the customer has not returned equipment the business day after the second call, notify the rep to create a rebill. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES", "RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        ordersNotYetReturned: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtPickupDate, rcq.sCompany, "
                + "rcq.sName, rcq.sPhone, rcq.sEmail, rcq.sState, rcq.sDName, rcq.sDPhone, rcq.sDState, "
                + "(SELECT COUNT(rqn.id) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS nLateCalls, "
                + "(SELECT MAX(rqn.dtTimeStamp) FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11) AS dtLastCall, "
                + "(SELECT TOP 1 sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rcq.id AND rqn.nCategory=11 ORDER BY rqn.dtTimeStamp desc) AS sLastNote "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.dtPickupDate > DATEADD(month,-1,GETDATE()) AND rcq.nDeliveryType IN (0,2) AND rcq.sStatus='Closed' AND rcq.dtPickupDate <= GETDATE() "
                + "AND ("
                    + "SELECT COUNT(rcq2.id) "
                    + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                    + "WHERE rcq2.nParentQuote=rcq.id AND rcq2.dtPickupDate>=rcq.dtPickupDate AND rcq2.sStatus<>'Discontinued' AND rcq2.bDeleted=0"
                + ") = 0 "
                + "AND ("
                    + "SELECT COUNT(p.id) "
                    + "FROM PACKAGES p "
                    + "WHERE p.sStatusCode NOT IN ('IT','DE') AND NOT (p.sStatusCode='AC' AND p.sCarrierCode='FedEx') "
                    + "AND p.nQuote=rcq.id AND p.nType=2 AND (p.bCompleted=0 OR p.bCompleted IS NULL) "
                + ") > 0 "
                + "ORDER BY rcq.dtPickupDate desc, rcq.id",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "sCompany", label: "Company Name"},
                {key: "nRep", label: "Sales Rep ID"},
                {key: "nDeliveryType", label: "Delivery or Shipping"},
                {key: "dtPickupDate", label: "Quote Pickup Date"},
                {key: "sCompany", label: "Customer Name"},
                {key: "sName", label: "Contact Name"},
                {key: "sPhone", label: "Contact Phone"},
                {key: "sEmail", label: "Contact Email"},
                {key: "sState", label: "Contact State"},
                {key: "sDName", label: "Delivery Contact Name"},
                {key: "sDPhone", label: "Delivery Contact Phone"},
                {key: "sDState", label: "Delivery Contact State"},
                {key: "nLateCalls", label: "Late Call Notes"},
                {key: "dtLastCall", label: "Date of Most Recent Last Call"},
                {key: "sLastNote", label: "Text of Most Recent Last Call Note"},
            ],
            desc: "Orders show up here if tracking hasn't been activated by the return date. "
                + "Send reminders to customers who are due to ship back today, and call them. "
                + "Make a note of type Logistics - Late Return Call. "
                + "Repeat this process the next business day. "
                + "If the customer has not returned equipment the business day after the second call, notify the rep to create a rebill. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES", "RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        packagesMissingPO: {
            query: "SELECT p.nQuote FROM PACKAGES p WHERE p.sTrackingNumber LIKE '%[0-9]%' AND (p.bCompleted=0 OR p.bCompleted IS NULL) AND p.npo is null AND p.dtLastUpdate >= '1/1/2019'",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote Number" },
            ],
            desc: "Lists all quotes where some tracking numbers are not assigned to a PO.",
            tables: ["PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        missingDeliveryTracking: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType, rcq.dtDeliveryDate, rcq.dtPickupDate, "
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Mac%' AND rp.nAttachedQuote=rcq.id) AS nMac, "
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Hartford%' AND rp.nAttachedQuote=rcq.id) AS nHartford "
                + "FROM RAC_Customer_Quotes rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.nDeliveryType IN (0,2) AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,-7,GETDATE()) AND rcq.dtDeliveryDate <= DATEADD(day,7,GETDATE()) AND "
                + "rcq.dtFirstClosed < GETDATE() AND rcq.sQuoteType='Rentacomputer.com' "
                + "ORDER BY rcq.dtDeliveryDate asc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtDeliveryDate", label: "Quote Delivery Date" },
                { key: "dtPickupDate", label: "Quote Pickup Date" },
                { key: "nMac", label: "Mac POs" },
                { key: "nHartford", label: "Hartford POs" },
            ],
            desc: "Lists all shipping orders due to deliver in the next week that are missing delivery tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        missingReturnTracking: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType,"
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.sCompany LIKE '%Hartford%' AND rp.nAttachedQuote=rcq.id) AS nHartford "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.id <> '1790676' AND rcq.nParentQuote IS NULL AND rcq.nDeliveryType IN (0,2) AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND nType=2) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtPickupDate >= DATEADD(day,-1,GETDATE()) AND rcq.dtPickupDate <= DATEADD(day,14,GETDATE()) AND "
                + "rcq.dtQuoteDate < GETDATE() "
                + "ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "nHartford", label: "Hartford POs" },
            ],
            desc: "Lists all shipping orders due to return in the next 2 weeks that are missing return tracking. ",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        packagesDeliveredNotCheckedIn: {
            query: "SELECT rcq.id, rcq.nRep, rcq.dtDeliveryDate, rcq.dtPickupdate, "
                + "(SELECT MAX(p.dtRealDelivery) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 "
                + "AND p.sStatusCode = 'DE' AND (p.bCompleted=0 OR p.bCompleted IS NULL)) AS dtRealDelivery "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE (SELECT COUNT(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND p.nType=1 "
                + "AND p.sStatusCode = 'DE' AND (p.bCompleted=0 OR p.bCompleted IS NULL)) > 0 "
                + "ORDER BY dtRealDelivery",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
                { key: "dtRealDelivery", label: "Actual Tracking Delivery Date" },
            ],
            desc: "Shows all tracking that has been delivered, but hasn't been confirmed yet."
                + "Verify customer has received equipment and remind them of our damage policy. "
                + "Deliveries should be checked in by the end of the next day. "
                + "For rentals longer than 5 days, put a follow up in for the rep to check in on the order halfway through the rental period.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        returnsDelivered: {
            query: "SELECT rp.id, p.nQuote, rp.sDisplayId, rp.sCompany AS sVendor, p.dtRealDelivery AS dtReturnDate"
                + " FROM PACKAGES p "
                + " INNER JOIN RAC_POS rp ON p.nPO = rp.id "
                + " WHERE p.nType = 2 AND p.sStatusCode = 'DE' AND (p.bCompleted = 0 OR p.bCompleted IS NULL) AND "
                + " p.dtRealDelivery < GETDATE() "
                + " ORDER BY dtRealDelivery",
            req: [],
            fields: [
                { key: "id", label: "PO ID" },
                { key: "nQuote", label: "Quote ID" },
                { key: "sDisplayId", label: "PO Number" },
                { key: "sVendor", label: "Vendor Name" },
                { key: "dtReturnDate", label: "Actual Return Tracking Delivery Date" },
            ],
            desc: "Shows all return tracking that has been delivered to the vendor but hasn't been confirmed yet."
                + " Verify the vendor received the return and there are no damages. "
                + " Returns must be checked in within 1 week of return. "
                + " After the return is checked in with the vendor, notify the customer and thank them for their business. "
                + " Ask happy customers for reviews. If you suspect the customer is not happy, notify Andy."
                + " To remove vendor deliveries, put 'CHECKED IN' in tracking.",
            tables: ["RAC_POS", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        needsSignedPackingSlip: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDeliveryType "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nParentQuote IS NULL AND rcq.nDeliveryType=1 AND "
                + "(SELECT Count(p.id) FROM PACKAGES p WHERE p.nQuote=rcq.id AND (p.sTrackingNumber='SPS' OR p.sTrackingNumber='No SPS')) = 0 AND "
                + "rcq.sStatus='Closed' AND rcq.dtDeliveryDate >= DATEADD(day,-1,GETDATE()) AND rcq.dtDeliveryDate <= GETDATE() "
                + "ORDER BY rcq.nDeliveryType desc, rcq.id",
            req: [],
            fields: [
                { key: "id", label: "Quote Number" },
                { key: "nRep", label: "Sales Rep ID" },
                { key: "nDeliveryType", label: "Delivery or Shipping" },
            ],
            desc: "Lists all quotes that need a signed packing slip sent back after they deliver. "
                + "Notify customer of requirement before delivery. "
                + "Ask for the signed packing slip the day after delivery. "
                + "Mark delivery as 'No SPS' or 'SPS' in tracking to remove them from this list.",
            tables: ["RAC_CUSTOMER_QUOTES", "PACKAGES"],
            type: "SELECT",
            whereUsed: ["components/pages/home/RacLogisticsHome.js"],
        },
        getContactsByCompany: {
            query: "SELECT c.id, c.sName, c.Email1, c.Email2, c.Email3, c.sPhone, c.sRep, c.sMainBrand, c.sTier "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany]",
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "Email1", label: "Email 1" },
                { key: "Email2", label: "Email 2" },
                { key: "Email3", label: "Email 3" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Fetches all contacts associated with a given company ID.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/CreateContactModal.js"],
        },
        createContact: {
            query: "INSERT INTO CONTACTS "
              + "(sName, nCompany, Email1, sPhone, sRep, sMainBrand, sTier) "
              + "VALUES ('[sName]', [nCompany], '[Email1]', '[sPhone]', [sRep], '[sMainBrand]', '[sTier]')",
            req: ["sName", "nCompany", "Email1", "sPhone", "sRep", "sMainBrand", "sTier"],
            fields: [
              { key: "sName", label: "Contact Name" },
              { key: "nCompany", label: "Company ID" },
              { key: "Email1", label: "Email 1" },
              { key: "sPhone", label: "Phone" },
              { key: "sRep", label: "Rep ID" },
              { key: "sMainBrand", label: "Main Brand" },
              { key: "sTier", label: "Tier" },
            ],
            desc: "Creates a new contact with the provided details.",
            tables: ["CONTACTS"],
            type: "INSERT",
            whereUsed: ["components/TierWizard.js"],
        },
        deleteContact: {
            query: "DELETE FROM CONTACTS "
              + "WHERE id = [id]",
            req: ["id"],
            fields: [
              { key: "id", label: "Contact ID" },
            ],
            desc: "Deletes a contact with the provided ID.",
            tables: ["CONTACTS"],
            type: "DELETE",
            whereUsed: ["components/CreateContactModal.js"],
        },
       
        insertCompany: {
            query: "INSERT INTO COMPANIES (sName) " +
                   "VALUES ('[sCompany]'); " +
                   "SELECT SCOPE_IDENTITY() AS nCompany;",  // Fetch the ID of the newly created company
            req: ["sCompany"],
            fields: [
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Inserts a new company into the COMPANIES table and returns the new nCompany ID.",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["components/CreateCompanyModal.js"],
        },
        
        
        createCompany: {
            query: `
                IF NOT EXISTS (SELECT 1 FROM COMPANIES WHERE sName = '[sCompany]')
                BEGIN
                    INSERT INTO COMPANIES (sName)
                    VALUES ('[sCompany]');
                    SELECT SCOPE_IDENTITY() AS id;
                END
                ELSE
                BEGIN
                    SELECT 'Company already exists' AS message;
                END;
            `,  // Fetch the ID of the newly created company or a message if the company already exists
            req: ["sCompany"],
            fields: [
                { key: "id", label: "Company ID" },
                { key: "message", label: "Message" },  // This will handle the "Company already exists" case
            ],
            desc: "Inserts a new company into the COMPANIES table if it does not already exist, and returns the new company's ID. If the company already exists, it returns a message indicating so.",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["components/CreateCompanyModal.js"],
        },
        
        

        
        updateQuoteWithCompany: {
            query: `
            UPDATE RAC_CUSTOMER_QUOTES
            SET nCompany = [nCompany]
            WHERE id = [quoteId]`
            ,
            req: ["nCompany", "quoteId"],
            fields: [
                { key: "nCompany", label: "Company ID" },
                { key: "quoteId", label: "Quote ID" },
            ],
            desc: "Updates the RAC_CUSTOMER_QUOTES table with the provided nCompany ID for the given quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/CreateCompanyModal.js"],
        },

        closedQuotes: {
            query: "SELECT TOP 10 rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed IS NOT NULL "
                + "AND rcq.dtManagerReviewed IS NULL "
                + "AND rcq.id <> 1766971 "  // Exclude the quote with ID 1766971
                + "ORDER BY rcq.dtFirstClosed DESC",
            req: [],
            fields: [
                { key: "id", label: "Quote ID" },
                { key: "sCompany", label: "Company Name" },
                { key: "sStatus", label: "Status" },
                { key: "dtFirstClosed", label: "Closed Date" },
            ],
            desc: "Fetches the most recently closed quotes that have not been reviewed by the manager, excluding quote ID 1766971.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },
        
        discontinuedQuotes: {
            query: `
              SELECT DISTINCT TOP 10 
                rcq.id, 
                rcq.sCompany, 
                rcq.nRep, 
                rcq.nCompany, 
                rcq.sStatus, 
                rcq.dtLastUpdated, 
                c.id AS companyId -- Pull company ID from COMPANIES
              FROM RAC_CUSTOMER_QUOTES rcq
              LEFT JOIN COMPANIES c ON rcq.nCompany = c.id
              WHERE rcq.sStatus = 'Discontinued'
              ORDER BY rcq.dtLastUpdated DESC
            `,
            req: [],
            fields: [
              { key: "id", label: "Quote ID" },
              { key: "sCompany", label: "Company Name" },
              { key: "sStatus", label: "Status" },
              { key: "dtLastUpdated", label: "Last Updated" },
              { key: "nRep", label: "Sales Rep" },
              { key: "companyId", label: "Company Exists" },  // Ensure this is correctly pulled
            ],
            desc: "Fetches the most recently discontinued quotes, verifies if the company exists, and pulls the associated sales rep (nRep).",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
          },
          
          
          
          
          
        
        notesOpen: {
            query: `
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn 
                WHERE rqn.nQuote IN (
                    SELECT rcq.id 
                    FROM RAC_CUSTOMER_QUOTES rcq 
                    WHERE rcq.sStatus = 'Open'
                )
                ORDER BY rqn.dtTimeStamp DESC
            `,
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to open quotes, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },
        
        notesClosed: {
            query: `
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn 
                WHERE rqn.nQuote IN ( 
                    SELECT TOP 10 rcq.id 
                    FROM RAC_CUSTOMER_QUOTES rcq 
                    WHERE rcq.dtFirstClosed IS NOT NULL AND rcq.dtManagerReviewed IS NULL 
                    ORDER BY rcq.dtFirstClosed DESC
                )
            `,
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to the most recently closed quotes, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },
        
        notesDiscontinued: {
            query: `
                SELECT rqn.*, rqn.nAttention, rqn.nAttentionPriority
                FROM RAC_QUOTE_NOTES rqn 
                WHERE rqn.nQuote IN ( 
                    SELECT TOP 10 rcq.id 
                    FROM RAC_CUSTOMER_QUOTES rcq 
                    WHERE rcq.sStatus='Discontinued' AND rcq.bDiscontinuedValid <> 1 
                    ORDER BY rcq.dtLastUpdated DESC
                )
            `,
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "sNote", label: "Note" },
                { key: "dtTimeStamp", label: "Timestamp" },
                { key: "nRep", label: "Rep" },
                { key: "nAttention", label: "Attention" },
                { key: "nAttentionPriority", label: "Attention Priority" },
            ],
            desc: "Fetches notes related to the most recently discontinued quotes, including attention and priority levels.",
            tables: ["RAC_QUOTE_NOTES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },
        

        checkCompanyByName: {
            query: "SELECT c.id AS nCompany "
                   + "FROM COMPANIES c "
                   + "WHERE LOWER(TRIM(c.sName)) = LOWER(TRIM('[sCompany]'))",
            req: ["sCompany"],
            fields: [
                { key: "nCompany", label: "Company ID" }
            ],
            desc: "Checks if a company exists in the COMPANIES table by name (sCompany) and returns its ID.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: ["components/DiscontinuedQuotesModal.js"],
        },
        
        

        checkCompanyExists: {
            query: `
              SELECT id
              FROM COMPANIES
              WHERE id = [nCompany]
            `,
            req: ["nCompany"],
            fields: [
              { key: "id", label: "Company ID" }
            ],
            desc: "Checks if a company with the given nCompany value exists in the COMPANIES table.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
          },        
        
        
        emailsOpen: {
            query: "SELECT rqe.nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject " +
                "FROM RAC_QUOTE_EMAILS rqe " +
                "WHERE rqe.nQuote IN ( " +
                "SELECT TOP 10 rcq.id " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.sStatus = 'Open' " +
                "ORDER BY rcq.dtQuoteDate DESC)",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to the most recently opened quotes, including the email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },
        

        emailsClosed: {
            query: "SELECT rqe.nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject " +
                "FROM RAC_QUOTE_EMAILS rqe " +
                "WHERE rqe.nQuote IN ( " +
                "SELECT TOP 10 rcq.id " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.dtFirstClosed IS NOT NULL AND rcq.dtManagerReviewed IS NULL " +
                "ORDER BY rcq.dtFirstClosed DESC)",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to the most recently closed quotes, including the email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        emailsDiscontinued: {
            query: "SELECT rqe.nQuote, rqe.dtDate, rqe.sBody, rqe.sFrom, rqe.sTo, rqe.sSubject " +
                "FROM RAC_QUOTE_EMAILS rqe " +
                "WHERE rqe.nQuote IN ( " +
                "SELECT TOP 10 rcq.id " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.sStatus='Discontinued' AND rcq.bDiscontinuedValid <> 1 " +
                "ORDER BY rcq.dtLastUpdated DESC)",
            req: [],
            fields: [
                { key: "nQuote", label: "Quote ID" },
                { key: "dtDate", label: "Date Sent" },
                { key: "sBody", label: "Email Body" },
                { key: "sTo", label: "Email To" },
                { key: "sFrom", label: "From" },
                { key: "sSubject", label: "Subject" },
            ],
            desc: "Fetches emails related to the most recently discontinued quotes, including the email body, sender, and subject.",
            tables: ["RAC_QUOTE_EMAILS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        contactsClosed: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed IS NOT NULL AND rcq.dtManagerReviewed IS NULL "
                + "ORDER BY rcq.dtFirstClosed DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts related to the most recently closed quotes.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        contactsDiscontinued: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Discontinued' AND rcq.bDiscontinuedValid <> 1 "
                + "ORDER BY rcq.dtLastUpdated DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts related to the most recently discontinued quotes.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/QuoteManager.js"],
        },

        contactsForSpecificCompany: {
            query: "SELECT c.* "
                  + "FROM CONTACTS c "
                  + "WHERE c.nCompany = [nCompany]",
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sPhone", label: "Phone" },
                { key: "Email1", label: "Email" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Fetches contacts for a specific company (nCompany).",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["components/CompanyDetailsModal.js"],
        },
        

        
        
        updateContact: {
            query: "UPDATE CONTACTS "
                + "SET sName = '[sName]', "
                + "nCompany = [nCompany], "
                + "Email1 = '[Email1]', "
                + "Email2 = '[Email2]', "
                + "Email3 = '[Email3]', "
                + "sPhone = '[sPhone]', "
                + "sRep = [sRep], "
                + "sMainBrand = '[sMainBrand]', "
                + "sTier = '[sTier]' "
                + "WHERE id = [id]",
            req: ["id", "sName", "nCompany", "Email1", "Email2", "Email3", "sPhone", "sRep", "sMainBrand", "sTier"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Email 1" },
                { key: "Email2", label: "Email 2" },
                { key: "Email3", label: "Email 3" },
                { key: "sPhone", label: "Phone" },
                { key: "sRep", label: "Rep ID" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Updates an existing contact with the provided details.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: ["components/QuoteManager.js"],
        },

        logMarketingContact: {
            query: "INSERT INTO CONTACT_LOG "
                + "(dtDate, nRep, nContact, sType, sDisposition, sNote) "
                + "VALUES (GETDATE(), [nRep], [nContact], '[sType]', '[sDisposition]', '[sNote]')",
            req: ["nRep", "nContact", "sType", "sDisposition", "sNote"],
            fields: [
                { key: "dtDate", label: "Date" },
                { key: "nRep", label: "Rep ID" },
                { key: "nContact", label: "Contact ID" },
                { key: "sType", label: "Contact Type" },
                { key: "sDisposition", label: "Disposition" },
                { key: "sNote", label: "Note" },
            ],
            desc: "Logs a marketing contact made by a rep.",
            tables: ["CONTACT_LOG"],
            type: "INSERT",
            whereUsed: ["components/QuoteManager.js"],
        },

        getQuoteDetailsById: {
            query: `
                DECLARE @quoteId INT = [quoteId];
                SELECT sName, sEmail, sPhone, nRep 
                FROM RAC_CUSTOMER_QUOTES 
                WHERE id = @quoteId;
            `,
            req: ["quoteId"], // Declaring the required parameter 'quoteId'
            fields: [
              { key: "sName", label: "Contact Name" },
              { key: "sEmail", label: "Email" },
              { key: "sPhone", label: "Phone" },
              { key: "nRep", label: "Rep ID" }
            ],
            desc: "Fetches contact details from RAC_CUSTOMER_QUOTES based on the provided quote ID.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["components/CreateContactModal.js", "components/CompanyDetailsModal.js"],
        },
        updateContactTier: {
            query: "UPDATE CONTACTS SET sTier = '[sTier]' WHERE id = [nContact]",
            req: ["sTier", "nContact"],
            fields: [
                { key: "sTier", label: "Tier" },
                { key: "nContact", label: "Contact ID" },
            ],
            desc: "Updates the tier (sTier) for the specified contact (nContact) in the CONTACTS table.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: ["components/QuoteManager.js"],
        },
        
    },
};
module.exports = {
    rac_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        newQuotes: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nDelivery, rcq.sStatus, rcq.sType, rcq.sCampaign, rcq.sLeadSource, rcq.sCompany, "
                + "rcq.sName, rcq.dtDateStamp, rcq.bDeleted, rcq.nLikelyToClose, rcq.nPotential, "
                + "(SELECT SUM(rql.nQuantity*rql.nPrice) FROM RAC_QUOTE_LINES rql WHERE rcq.id=rql.nQuote) AS nLinesTotal, "
                + "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote=rcq.id) AS POTotal, "
                + "(SELECT MIN(rcq2.dtFirstClosed) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq.nCompany=rcq2.nCompany AND rcq2.sStatus='Closed') AS FirstSale, "
                + "(SELECT COUNT(rr.id) FROM RAC_RFQ rr WHERE rr.nQuote = rcq.id) AS RFQsSent, "
                + "(SELECT COUNT(rr.id) FROM RAC_RFQ rr WHERE rr.nQuote = rcq.id AND rr.sApples='Full Match') AS RFQsFullMatch "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtDateStamp > DATEADD(month, -[numMonths], GETDATE()) AND rcq.dtDateStamp <= GETDATE() AND rcq.sQuoteType='Rentacomputer.com'",
            req: ["numMonths"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets data about Rentacomputer quotes that were opened in the last [numMonths] months.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        recentlyClosed: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.sQuoteTags, rcq.bTaxDel, rcq.nTaxRate, rcq.nDelivery, rcq.sCompany, " +
                "rcq.sName, rcq.sQuoteTitle, rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, " +
                "rcq.dtDateStamp, rcq.dtFirstClosed, sType, sCampaign, sLeadSource, " +
                "(SELECT SUM(rql.nQuantity*rql.nPrice) FROM RAC_QUOTE_LINES rql WHERE rcq.id=rql.nQuote) AS nLinesTotal, " +
                "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote=rcq.id) AS POTotal, " + 
                "(SELECT MIN(rcq2.dtFirstClosed) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq.nCompany=rcq2.nCompany AND rcq2.sStatus='Closed') AS FirstSale " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.sStatus='Closed' AND rcq.dtFirstClosed > DATEADD(month, -[numMonths], GETDATE()) AND rcq.dtFirstClosed <= GETDATE() AND rcq.sQuoteType='Rentacomputer.com' " +
                "ORDER BY dtComplete desc",
            req: ["numMonths"],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Sales Rep"},
                {key: "nRep2", label: "Sales Rep 2"},
                {key: "nRep3", label: "Sales Rep 3"},
                {key: "nQuoteTotal", label: "Total Price"},
                {key: "bTaxDel", label: "Tax Delivery?"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "nDelivery", label: "Delivery Price"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sCompany", label: "Company Name"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "sStatus", label: "Quote Status"},
                {key: "sName", label: "Contact Name"},
                {key: "sAddress", label: "Address"},
                {key: "sAddress2", label: "Address Line 2"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "sZip", label: "Zip"},
                {key: "dtComplete", label: "Install Complete Date"},
                {key: "dtTargetComplete", label: "Target Complete Date"},
                {key: "dtDateStamp", label: "Quote Date"},
                {key: "dtFirstClosed", label: "Quote First Closed Date"},
                {key: "dtSiteVisit", label: "Site Visit Date"},
                
            ],
            desc: "Gets data about Rentacomputer quotes that were first closed in the last [numMonths] months.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/RecentlyCompleted.js"],
        },
        getDiscontinuedQuoteRFQs: {
            query: "SELECT rr.*, ("
                    + "SELECT SUM(rrl.nPrice*rrl.nQuantity) "
                    + "FROM RAC_RFQ_LINES rrl "
                    + "WHERE rrl.nRFQ = rr.id) AS totalPrice "
                + "FROM RAC_RFQ rr "
                + "WHERE rr.nQuote IN ("
                    + "SELECT TOP 10 rcq.id "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com'" 
                    + "ORDER BY rcq.dtLastUpdated desc) "
                + "ORDER BY rr.dtSent desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all RFQs from discontinued quotes",
            tables: ["RAC_RFQ", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getDiscontinuedQuoteNotes: {
            query: "SELECT rqn.* "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "WHERE rqn.nQuote IN ("
                    + "SELECT TOP 10 rcq.id "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com'"
                    + "ORDER BY rcq.dtLastUpdated desc) "
                + "AND rqn.nType != 2 "
                + "ORDER BY rqn.dtTimeStamp desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all notes from discontinued quotes",
            tables: ["RAC_QUOTE_NOTES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getDiscontinuedQuotePOs: {
            query: "SELECT rp.* "
                + "FROM RAC_POS rp "
                + "WHERE rp.nAttachedQuote IN ("
                    + "SELECT TOP 10 rcq.id "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com'"
                    + "ORDER BY rcq.dtLastUpdated desc) "
                + "ORDER BY rp.sDisplayId desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all POs from discontinued quotes",
            tables: ["RAC_POS", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getDiscontinuedQuoteFollowups: {
            query: "SELECT rs.* "
                + "FROM RAC_SCHEDULE rs "
                + "WHERE rs.nQuote IN ("
                    + "SELECT TOP 10 rcq.id "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com'"
                    + "ORDER BY rcq.dtLastUpdated desc) "
                + "ORDER BY rs.dtDate desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets followups related to discontinued quotes",
            tables: ["RAC_SCHEDULE", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getDiscontinuedQuoteLines: {
            query: "SELECT rql.* "
                + "FROM RAC_QUOTE_LINES rql "
                + "WHERE rql.nQuote IN ("
                    + "SELECT TOP 10 rcq.id "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com'"
                    + "ORDER BY rcq.dtLastUpdated desc) "
                + "ORDER BY rql.nSort desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets quote lines related to discontinued quotes",
            tables: ["RAC_QUOTE_LINES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getDiscontinuedQuotes: {
            query: "SELECT TOP 10 rcq.id, rcq.sCompany, rcq.nRep, "
                + "rcq.dtDateStamp, rcq.dtLastUpdated, rcq.dtPickupDate, rcq.dtDeliveryDate, "
                + "c.sName, c.sIndustry, c.sSubIndustry, c.nEmployees,c.nCompanyAnnualSales "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN COMPANIES c on rcq.nCompany=c.id "
                + "WHERE NOT rcq.bDiscontinuedValid=1 AND rcq.sQuoteType='Rentacomputer.com' "
                + "ORDER BY rcq.dtLastUpdated desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets discontinued quotes that haven't been reviewed yet",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        removePreDeliveryVendorCheck: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET bPreDeliveryVendorCheck=0, "
                + "dtPreDeliveryVendorCheck = null, "
                + "nPreDeliveryVendorCheck = null "
                + "WHERE id=[QuoteID] ",
            req: ["QuoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Completes  the Pre-Delivery Vendor Check for a given quote.  Must provide quote ID",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/tables/logistics/RacPreDeliveryVendorCheck.js"],
        },
        completePreDeliveryVendorCheck: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET bPreDeliveryVendorCheck=1, "
                + "dtPreDeliveryVendorCheck = GETDATE(), "
                + "nPreDeliveryVendorCheck = [RepID] "
                + "WHERE id=[QuoteID] ",
            req: ["RepID", "QuoteID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Completes  the Pre-Delivery Vendor Check for a given quote.  Must provide sales rep ID, and quote ID",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["components/tables/logistics/RacPreDeliveryVendorCheck.js"],
        },
        preDeliveryCheck: {
            query: "SELECT rcq.id, rcq.sCompany, rcq.sName, rcq.sDCompany, rcq.sDName, rcq.sDAddress, rcq.sDAddress2, rcq.sDCity, rcq.sDState, rcq.sDZip,"
                + "dtPickupDate, rcq.dtDeliveryDate, rcq.dtFirstClosed, rcq.dtDateStamp, rcq.nRep, rcq.nDeliveryType, "
                + "rcq.bPreDeliveryVendorCheck, rcq.dtPreDeliveryVendorCheck, rcq.nPreDeliveryVendorCheck, rcq.sPreDeliveryVendorCheckNotes, "
                + "rql.sDescription, rp.sCompany AS Vendor, rp.sName AS VendorContact, rp.sPhone AS VendorPhone, rp.sEmail AS VendorEmail "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN RAC_POS rp ON rcq.id=rp.nAttachedQuote "
                + "LEFT JOIN RAC_QUOTE_LINES rql ON rcq.id = rql.nQuote "
                + "WHERE "
                + "((rql.nPrice * rql.nQuantity) = (SELECT MAX(nPrice * nQuantity) FROM RAC_QUOTES_LINES WHERE nQuote=rcq.id))"
                + "AND rcq.dtDeliveryDate >= DATEADD(day,-10,GETDATE()) AND NOT rcq.bPreDeliveryVendorCheck = 1 AND rcq.sQuoteType='Rentacomputer.com'",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "sCompany", label: "Customer Company"},
                {key: "sName", label: "Customer Contact"},
                {key: "sDCompany", label: "Delivery Company"},
                {key: "sDName", label: "Delivery Contact"},
                {key: "sDAddress", label: "Delivery Address"},
                {key: "sDAddress2", label: "Delivery Address2"},
                {key: "sDCity", label: "Delivery City"},
                {key: "sDState", label: "Delivery State"},
                {key: "sDZip", label: "Delivery Zip"},
                {key: "dtPickupDate", label: "Pickup/Return Date"},
                {key: "dtDeliveryDate", label: "Delivery/Shipping Date"},
                {key: "dtFirstClosed", label: "First Closed Date"},
                {key: "dtDateStamp", label: "Quote Creation Date"},
                {key: "nRep", label: "Sales Rep Number"},
                {key: "nDeliveryType", label: "Delivery/Shipping Type"},
                {key: "bPreDeliveryVendorCheck", label: "Pre-Delivery Vendor Check Completed?"},
                {key: "dtPreDeliveryVendorCheck", label: "Pre-Delivery Vendor Check Completed Date"},
                {key: "nPreDeliveryVendorCheck", label: "Pre-Delivery Vendor Check Completed by Who"},
                {key: "sPreDeliveryVendorCheckNotes", label: "Pre-Delivery Vendor Check Completed Notes"},
                {key: "sDescription", label: "Largest Item Description"},
                {key: "Vendor", label: "Vendor Name"},
                {key: "VendorContact", label: "Vendor Contact"},
                {key: "VendorPhone", label: "Vendor Phone"},
                {key: "VendorEmail", label: "Vendor Email"},
            ],
            desc: "Lists all closed RAC quotes with delivery date less than 10 days from now, and information about the quote, "
                + "the highest sales $ item on the quote, and the vendor.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_POS", "RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: [
                "components/tables/logistics/RacPreDeliveryVendorCheck.js",
                "components/tables/sales/RacPreDeliveryVendorCheck.js",
            ],
        },


    }

} 
module.exports = {
    marketing_tier_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getOpenRentals: {
            query: "SELECT rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sQuoteType='Rentacomputer.com' "
                + "AND rcq.dtDeliveryDate < DATEADD(month,1,GETDATE()) "
                + "AND rcq.dtPickupDate > DATEADD(month,-2,GETDATE()) "
                + "AND rcq.sStatus = 'Closed' "
                + "ORDER BY rcq.dtDeliveryDate asc",
            req: [],
            fields: [
                { key: "", label: "" },
            ],
            desc: "Gets all open rentals.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getClosedQuotes: {
            query: "SELECT TOP 10 rcq.id, rcq.nCompany, rcq.sCompany, rcq.sName, rcq.sEmail, rcq.sPhone, rcq.nRep " // Include nRep in the query
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed IS NOT NULL "
                + "AND rcq.dtManagerReviewed IS NULL "
                + "ORDER BY rcq.dtFirstClosed DESC",
            req: [],
            fields: [
                { key: "id", label: "Quote ID" },
                { key: "sCompany", label: "Company Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sEmail", label: "Email" },
                { key: "sPhone", label: "Phone Number" },
                { key: "nRep", label: "Rep ID" }, // Add this line to map the nRep value
            ],
            desc: "Gets the most recently closed quotes that have not been reviewed by a manager.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        
        
        getDiscontinuedQuotes: {
            query: "SELECT TOP 10 rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Discontinued' "
                + "AND rcq.bDiscontinuedValid <> 1 "
                + "ORDER BY rcq.dtLastUpdated DESC",
            req: [],
            fields: [
                { key: "id", label: "Quote ID" },
                { key: "sCompany", label: "Company Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "sName", label: "Contact Name" },
                { key: "sEmail", label: "Email" },
                { key: "sPhone", label: "Phone Number" },
            ],
            desc: "Gets the most recently discontinued quotes that are not yet marked as valid.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getContactsFromClosedQuotes: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.dtFirstClosed IS NOT NULL "
                + "AND rcq.dtManagerReviewed IS NULL "
                + "ORDER BY rcq.dtFirstClosed DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "sEmail", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                // Add more fields as needed
            ],
            desc: "Gets contacts from the companies associated with the most recently closed quotes that have not been reviewed by a manager.",
            tables: ["CONTACTS", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getContactsFromDiscontinuedQuotes: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany IN ( "
                + "SELECT TOP 10 rcq.nCompany "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Discontinued' "
                + "AND rcq.bDiscontinuedValid <> 1 "
                + "ORDER BY rcq.dtLastUpdated DESC)",
            req: [],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "sEmail", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                // Add more fields as needed
            ],
            desc: "Gets contacts from the companies associated with the most recently discontinued quotes that are not yet marked as valid.",
            tables: ["CONTACTS", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getContactsForCompany: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany]",
            req: ["nCompany"],
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                // Add more fields as needed
            ],
            desc: "Gets contacts for a specific company by nCompany.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: [],
        },
        checkContactExists: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany] "
                + "AND c.sName = '[sName]' ", // Or use email: AND c.Email1 = '[sEmail]'
            req: ["nCompany", "sName"], // Or "sEmail" if checking by email
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                // Add more fields as needed
            ],
            desc: "Checks if a specific contact exists for a given company.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: [],
        },
        createNewContact: {
            query: "INSERT INTO CONTACTS "
                + "(sName, nCompany, Email1, Email2, Email3, sPhone, sRep, sMainBrand, sTier, dtDateAdded) " // Use sRep instead of nRep
                + "VALUES ('[sName]', [nCompany], '[Email1]', '[Email2]', '[Email3]', '[sPhone]', '[sRep]', 'Rentacomputer.com', '[sTier]', GETDATE())",
            req: ["sName", "nCompany", "Email1", "Email2", "Email3", "sPhone", "sRep", "sMainBrand", "sTier"],
            fields: [
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Primary Email" },
                { key: "Email2", label: "Secondary Email" },
                { key: "Email3", label: "Tertiary Email" },
                { key: "sPhone", label: "Phone Number" },
                { key: "sRep", label: "Rep Name" }, // Use sRep
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Inserts a new contact into the CONTACTS table.",
            tables: ["CONTACTS"],
            type: "INSERT",
            whereUsed: [],
        },
        updateContact: {
            query: "UPDATE CONTACTS "
                + "SET sName = '[sName]', "
                + "nCompany = [nCompany], "
                + "Email1 = '[Email1]', "
                + "Email2 = '[Email2]', "
                + "Email3 = '[Email3]', "
                + "sPhone = '[sPhone]', "
                + "sRep = '[sRep]', "
                + "sMainBrand = '[sMainBrand]', "
                + "sTier = '[sTier]' "
                + "WHERE id = [id]",
            req: ["sName", "nCompany", "Email1", "sPhone", "sTier", "sRep", "sMainBrand", "id"],
            fields: [
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                { key: "sRep", label: "Rep Name" },
                { key: "sMainBrand", label: "Main Brand" },
                { key: "sTier", label: "Tier" },
            ],
            desc: "Updates an existing contact's information in the CONTACTS table.",
            tables: ["CONTACTS"],
            type: "UPDATE",
            whereUsed: [],
        },
        getContactForQuote: {
            query: "SELECT c.* "
                + "FROM CONTACTS c "
                + "WHERE c.nCompany = [nCompany] "
                + "AND c.sName = '[sName]'",  // Fetch the contact by company ID and name
            req: ["nCompany", "sName"],  // Required parameters for the query
            fields: [
                { key: "id", label: "Contact ID" },
                { key: "sName", label: "Contact Name" },
                { key: "nCompany", label: "Company ID" },
                { key: "Email1", label: "Primary Email" },
                { key: "sPhone", label: "Phone Number" },
                { key: "sRep", label: "Rep Name" },
                { key: "sTier", label: "Tier" },
                // Add more fields as needed
            ],
            desc: "Fetches the specific contact for a given company and contact name associated with a quote.",
            tables: ["CONTACTS"],
            type: "SELECT",
            whereUsed: ["MarketingTierWizard", "EditContactModal"],
        },    
        createCompany: {
            query: "INSERT INTO COMPANIES (sName) "
                + "VALUES ('[sName]'); "
                + "SELECT SCOPE_IDENTITY() AS id;",
            req: ["sName"],
            fields: [
                { key: "id", label: "New Company ID" }, // The returned value will be the `id` from the COMPANIES table
            ],
            desc: "Inserts a new company into the COMPANIES table and returns the new company ID (`id`).",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["CreateCompanyModal"],
        },

        
        createNewCompany: {
            query: "INSERT INTO COMPANIES (sName) VALUES ('[sName]'); SELECT SCOPE_IDENTITY() AS nCompany;",
            req: ["sName"],
            fields: [
                { key: "sName", label: "Company Name" },
                { key: "nCompany", label: "Company ID" },
            ],
            desc: "Inserts a new company and returns the generated Company ID (nCompany).",
            tables: ["COMPANIES"],
            type: "INSERT",
            whereUsed: ["CreateCompanyModal"],
        },
        checkCompanyExists: {
            query: "SELECT id FROM COMPANIES WHERE sName = '[sName]'",
            req: ["sName"],
            fields: [
                { key: "id", label: "Company ID" },
            ],
            desc: "Checks if a company exists in the COMPANIES table by sName and returns the ID if it exists.",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: ["MarketingTierWizard"],
        },
        
        updateQuoteWithCompany: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET nCompany = [id] " // Using the `id` from COMPANIES as `nCompany`
                + "WHERE id = [quoteId];", // Using the `id` from RAC_CUSTOMER_QUOTES to identify the correct quote
            req: ["id", "quoteId"], // Using `id` (from COMPANIES) to update `nCompany` in RAC_CUSTOMER_QUOTES
            fields: [],
            desc: "Updates the RAC_CUSTOMER_QUOTES table with the new company ID (`id` from COMPANIES) for the specific quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["CreateCompanyModal"],
        },
        
        
               
        
    }

} 
export const topNavLinks = [
    {
        id: "TA Search",
        link: "/ta-search",
        text: "TA Search",
        permissions: ["Internal"],
    },
    {
        id: "All Quotes",
        link: "/all-quotes",
        text: "All Quotes",
        permissions: ["Internal"],
    },
    {
        id: "Custom Query",
        link: "/custom-query",
        text: "Custom Query",
        permissions: ["admin", "Dev"],
    },
]

export const leftNavLinks = [
    {
        id: "Home",
        link: "/home",
        icon: "fas fa-house-user",
        text: "Home",
        permissions: ["Internal"],
    },
    {
        id: "Admin Dashboard",
        link: "/admin-dashboard",
        icon: "fas fa-chess-king",
        text: "Admin Dashboard",
        permissions: ["admin"],
    },
    {
        id: "Accounting Dashboard",
        link: "/accounting-dashboard",
        icon: "fas fa-usd",
        text: "Accounting Dashboard",
        permissions: ["accounting"],
    },
    {
        id: "Logistics Dashboard",
        link: "/logistics-dashboard",
        icon: "fa fa-truck",
        text: "Logistics Dashboard",
        permissions: ["RAC"],
    },
    {
        id: "CAM/CSN Dashboard",
        link: "/cam-csn-dashboard",
        icon: "fas fa-shield-alt",
        text: "CAM/CSN Dashboard",
        permissions: ["CSN", "Dev"],
    },
    {
        id: "CAM Operations",
        link: "/cam-operations",
        icon: "fas fa-video",
        text: "CAM Operations",
        permissions: ["CSN", "Dev"],
    },
    {
        id: "RAC Dashboard",
        link: "/rac-dashboard",
        icon: "fas fa-desktop",
        text: "RAC Dashboard",
        permissions: ["RAC"],
    },
    {
        id: "RAC Operations",
        link: "/rac-operations",
        icon: "fas fa-desktop",
        text: "RAC Operations",
        permissions: ["RAC"],
    },
    {
        id: "Development Tutorial",
        link: "/tutorial",
        icon: "fa fa-question",
        text: "Development Tutorial",
        permissions: ["Dev"],
    },
    {
        id: "Underwriting Dashboard",
        link: "/underwriting-dashboard",
        icon: "fa fa-question",
        text: "Underwriting Dashboard",
        permissions: ["Dev", "admin"],
    },
    {
        id: "Marketing Dashboard",
        link: "/marketing-dashboard",
        icon: "fa fa-question",
        text: "Marketing Dashboard",
        permissions: ["admin", "Dev"],
    },
    {
        id: "Marketing Tier Wizard",
        link: "/marketing-tier-wizard",
        icon: "fa fa-hat-wizard",
        text: "Marketing Tier Wizard",
        permissions: ["Dev", "admin"],
    },
]